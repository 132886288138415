import { AnyColorLevel } from '../../../../types/color';
import { PreviewFontColorByWeight, PreviewFontSize } from '../../types/font';
import PreviewText from '../Text/PreviewText';

type WeightedPreviewTextProps = {
	size: PreviewFontSize;
	color?: AnyColorLevel;
};

const NormalPreviewText = ({ size, color }: WeightedPreviewTextProps) => {
	return color ? (
		<PreviewText size={size} weight="normal" color={color} />
	) : null;
};

const LightPreviewText = ({ size, color }: WeightedPreviewTextProps) => {
	return color ? (
		<PreviewText size={size} weight="light" color={color} />
	) : null;
};

export type PreviewTextSizeGroupProps = {
	size: PreviewFontSize;
	colorByWeight: PreviewFontColorByWeight;
};

const PreviewTextSizeGroup = ({
	size,
	colorByWeight,
}: PreviewTextSizeGroupProps) => {
	return (
		<div className="PreviewTextGroup">
			<PreviewText size={size} weight="bold" color={colorByWeight.bold} />
			<NormalPreviewText size={size} color={colorByWeight.normal} />
			<LightPreviewText size={size} color={colorByWeight.light} />
		</div>
	);
};

export default PreviewTextSizeGroup;
