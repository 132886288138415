import { AnyBackgroundColorLevel } from '../../../types/color';
import { PreviewFontColorByWeightBySize } from '../types/font';

export type FontColorByWeightBySizeByBackground = {
	[ColorLevel in AnyBackgroundColorLevel]: PreviewFontColorByWeightBySize;
};

export const fontColorByWeightBySizeByBackground: FontColorByWeightBySizeByBackground =
	{
		APCA_WHITE: {
			36: {
				bold: 'APCA_L450',
				normal: 'APCA_L600',
				light: 'APCA_L750',
			},
			24: {
				bold: 'APCA_L450',
				normal: 'APCA_L600',
				light: 'APCA_L750',
			},
			18: {
				bold: 'APCA_L600',
				normal: 'APCA_L750',
				light: 'APCA_BLACK',
			},
			16: {
				bold: 'APCA_L600',
				normal: 'APCA_L900',
			},
			14: {
				bold: 'APCA_L750',
			},
		},
		APCA_BLACK: {
			36: {
				bold: 'APCA_D450',
				normal: 'APCA_D600',
				light: 'APCA_D750',
			},
			24: {
				bold: 'APCA_D450',
				normal: 'APCA_D600',
				light: 'APCA_D750',
			},
			18: {
				bold: 'APCA_D600',
				normal: 'APCA_D750',
				light: 'APCA_WHITE',
			},
			16: {
				bold: 'APCA_D600',
				normal: 'APCA_D900',
			},
			14: {
				bold: 'APCA_D750',
			},
		},
		APCA_L150: {
			36: {
				bold: 'APCA_L600',
				normal: 'APCA_L750',
				light: 'APCA_L900',
			},
			24: {
				bold: 'APCA_L600',
				normal: 'APCA_L750',
				light: 'APCA_L900',
			},
			18: {
				bold: 'APCA_L750',
				normal: 'APCA_L900',
			},
			16: {
				bold: 'APCA_L750',
				normal: 'APCA_BLACK',
			},
			14: {
				bold: 'APCA_L900',
			},
		},
		APCA_D150: {
			36: {
				bold: 'APCA_D600',
				normal: 'APCA_D750',
				light: 'APCA_D900',
			},
			24: {
				bold: 'APCA_D600',
				normal: 'APCA_D750',
				light: 'APCA_D900',
			},
			18: {
				bold: 'APCA_D750',
				normal: 'APCA_D900',
			},
			16: {
				bold: 'APCA_D900',
				normal: 'APCA_WHITE',
			},
			14: {
				bold: 'APCA_D900',
			},
		},
		WCAG_2_1_AAA_BLACK: {
			36: {
				bold: 'WCAG_2_1_AAA_L500',
				normal: 'WCAG_2_1_AAA_L500',
				light: 'WCAG_2_1_AAA_L500',
			},
			24: {
				bold: 'WCAG_2_1_AAA_L500',
				normal: 'WCAG_2_1_AAA_L500',
				light: 'WCAG_2_1_AAA_L500',
			},
			20: {
				bold: 'WCAG_2_1_AAA_L500',
				normal: 'WCAG_2_1_AAA_L100',
				light: 'WCAG_2_1_AAA_L100',
			},
			16: {
				bold: 'WCAG_2_1_AAA_L100',
				normal: 'WCAG_2_1_AAA_L100',
				light: 'WCAG_2_1_AAA_L100',
			},
			14: {
				bold: 'WCAG_2_1_AAA_L100',
				normal: 'WCAG_2_1_AAA_L100',
				light: 'WCAG_2_1_AAA_L100',
			},
		},
		WCAG_2_1_AAA_L900: {
			36: {
				bold: 'WCAG_2_1_AAA_L300',
				normal: 'WCAG_2_1_AAA_L300',
				light: 'WCAG_2_1_AAA_L300',
			},
			24: {
				bold: 'WCAG_2_1_AAA_L300',
				normal: 'WCAG_2_1_AAA_L300',
				light: 'WCAG_2_1_AAA_L300',
			},
			20: {
				bold: 'WCAG_2_1_AAA_L300',
				normal: 'WCAG_2_1_AAA_L100',
				light: 'WCAG_2_1_AAA_L100',
			},
			16: {
				bold: 'WCAG_2_1_AAA_L100',
				normal: 'WCAG_2_1_AAA_L100',
				light: 'WCAG_2_1_AAA_L100',
			},
			14: {
				bold: 'WCAG_2_1_AAA_L100',
				normal: 'WCAG_2_1_AAA_L100',
				light: 'WCAG_2_1_AAA_L100',
			},
		},
		WCAG_2_1_AAA_WHITE: {
			36: {
				bold: 'WCAG_2_1_AAA_L500',
				normal: 'WCAG_2_1_AAA_L500',
				light: 'WCAG_2_1_AAA_L500',
			},
			24: {
				bold: 'WCAG_2_1_AAA_L500',
				normal: 'WCAG_2_1_AAA_L500',
				light: 'WCAG_2_1_AAA_L500',
			},
			20: {
				bold: 'WCAG_2_1_AAA_L500',
				normal: 'WCAG_2_1_AAA_L900',
				light: 'WCAG_2_1_AAA_L900',
			},
			16: {
				bold: 'WCAG_2_1_AAA_L900',
				normal: 'WCAG_2_1_AAA_L900',
				light: 'WCAG_2_1_AAA_L900',
			},
			14: {
				bold: 'WCAG_2_1_AAA_L900',
				normal: 'WCAG_2_1_AAA_L900',
				light: 'WCAG_2_1_AAA_L900',
			},
		},
		WCAG_2_1_AAA_L100: {
			36: {
				bold: 'WCAG_2_1_AAA_L700',
				normal: 'WCAG_2_1_AAA_L700',
				light: 'WCAG_2_1_AAA_L700',
			},
			24: {
				bold: 'WCAG_2_1_AAA_L700',
				normal: 'WCAG_2_1_AAA_L700',
				light: 'WCAG_2_1_AAA_L700',
			},
			20: {
				bold: 'WCAG_2_1_AAA_L700',
				normal: 'WCAG_2_1_AAA_L900',
				light: 'WCAG_2_1_AAA_L900',
			},
			16: {
				bold: 'WCAG_2_1_AAA_L900',
				normal: 'WCAG_2_1_AAA_L900',
				light: 'WCAG_2_1_AAA_L900',
			},
			14: {
				bold: 'WCAG_2_1_AAA_L900',
				normal: 'WCAG_2_1_AAA_L900',
				light: 'WCAG_2_1_AAA_L900',
			},
		},
	} as const;

const FontColorHelper = {
	fontColorByWeightBySizeByBackground: fontColorByWeightBySizeByBackground,
};
export default FontColorHelper;
