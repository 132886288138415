import React, { useContext } from 'react';
import {
	AnyBackgroundColorLevel,
	colorLevelDisplayMap,
} from '../../../../types/color';
import { PaletteStateContext } from '../../../palette/contexts/PaletteStateContext';
import { ColorModeContext } from '../../contexts/ColorModeContext';
import FontColorHelper from '../../helpers/FontColorHelper';
import PreviewButtonGroup from '../ButtonGroup/PreviewButtonGroup';
import PreviewTextGroup from '../TextGroup/PreviewTextGroup';
import './PreviewContent.css';

export type PreviewContentProps = {
	backgroundColorLevel: AnyBackgroundColorLevel;
	children?: React.ReactNode;
};

const PreviewContent = ({
	backgroundColorLevel,
	children,
}: PreviewContentProps) => {
	const { palette } = useContext(PaletteStateContext);
	const colorMode = useContext(ColorModeContext);
	const labelColor =
		colorMode === 'light'
			? palette.colors.APCA_BLACK
			: palette.colors.APCA_WHITE;
	return (
		<div
			className="PreviewContent"
			style={{
				backgroundColor: palette.colors[backgroundColorLevel],
			}}
		>
			<p
				className="PreviewContent--background-label"
				style={{ color: labelColor }}
			>
				{colorLevelDisplayMap[backgroundColorLevel].label} Background
			</p>
			<PreviewTextGroup
				colorByWeightBySize={
					FontColorHelper.fontColorByWeightBySizeByBackground[
						backgroundColorLevel
					]
				}
			/>
			<PreviewButtonGroup />
			{children}
		</div>
	);
};

export default PreviewContent;
