import { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { ColorContrastStandard } from '../../../../types/color';
import {
	PalettesDispatchContext,
	PalettesStateContext,
} from '../../contexts/PalettesStateContext';
import './PalettesForm.css';

const PalettesForm = () => {
	const palettesState = useContext(PalettesStateContext);
	const palettesDispatch = useContext(PalettesDispatchContext);
	const onChangeBaseColor = (baseColor: string, index: number) => {
		if (palettesDispatch) {
			palettesDispatch({
				type: 'setBaseColor',
				payload: {
					index,
					color: baseColor,
				},
			});
		}
	};
	const onChangeStandard = (standard: ColorContrastStandard) => {
		if (palettesDispatch) {
			palettesDispatch({
				type: 'setStandard',
				payload: {
					standard,
				},
			});
		}
	};

	return (
		<div className="PalettesForm">
			<Form>
				<h2>Choose your color</h2>
				<div className="PalettesForm--base-color PalettesForm-base-color">
					<label htmlFor="color0" className="PalettesForm-base-color--label">
						Color:
					</label>
					<input
						id="color0"
						className="PalettesForm-base-color--input"
						type="color"
						name="color0"
						value={palettesState.palettes[0].base}
						onChange={(e) => onChangeBaseColor(e.target.value, 0)}
					/>
					<span className="PalettesForm-base-color--value">
						{palettesState.palettes[0].base}
					</span>
				</div>
				<Form.Group className="PalettesForm--standard PalettesForm-standard">
					<label htmlFor="color0" className="PalettesForm-standard--label">
						Contrast Standard:
					</label>
					<Form.Check
						id="standard-wcag21aaa:"
						className="PalettesForm-standard--input"
						label="WCAG 2.1 AAA"
						name="standard"
						type="radio"
						value="wcag21aaa:"
						checked={palettesState.standard === 'wcag21aaa'}
						onChange={() => onChangeStandard('wcag21aaa')}
					/>
					<Form.Check
						id="standard-apca"
						className="PalettesForm-standard--input"
						label="APCA"
						name="standard"
						type="radio"
						value="apca"
						checked={palettesState.standard === 'apca'}
						onChange={() => onChangeStandard('apca')}
					/>
				</Form.Group>
				{/* <div>
						<input type="color" name="color1" value={palettes[1].base} onChange={(e) => onChangeBaseColor(e, 1)} />
						<span>{palettes[1].base}</span>
					</div>
					<div>
						<input type="color" name="color2" value={palettes[2].base} onChange={(e) => onChangeBaseColor(e, 2)} />
						<span>{palettes[2].base}</span>
					</div> */}
			</Form>
		</div>
	);
};

export default PalettesForm;
