import { Tab, Tabs } from 'react-bootstrap';
import './App.css';
import Export from './modules/export/components/Export/Export';
import PalettesForm from './modules/palette/components/Form/PalettesForm';
import Palette from './modules/palette/components/Palette/Palette';
import {
	PaletteState,
	PaletteStateContext,
} from './modules/palette/contexts/PaletteStateContext';
import {
	PalettesDispatchContext,
	PalettesStateContext,
} from './modules/palette/contexts/PalettesStateContext';
import { usePalettesReducer } from './modules/palette/reducers/usePalettesReducer';
import Preview from './modules/preview/components/Preview/Preview';

function App() {
	const [palettesState, dispatch] = usePalettesReducer('apca', ['#777777']);
	const paletteState: PaletteState = {
		standard: palettesState.standard,
		palette: palettesState.palettes[0],
		colorLevels: palettesState.colorLevels,
	};
	return (
		<div className="App">
			<header className="App--header">
				<h1 className="App--title">Palette Ally</h1>
				<PalettesStateContext.Provider value={palettesState}>
					<PalettesDispatchContext.Provider value={dispatch}>
						<section className="App--palette-form">
							<PalettesForm />
						</section>
					</PalettesDispatchContext.Provider>
				</PalettesStateContext.Provider>
				<PaletteStateContext.Provider value={paletteState}>
					<section className="App--palette">
						<Palette />
					</section>
				</PaletteStateContext.Provider>
			</header>
			<div className="App--body">
				<PaletteStateContext.Provider value={paletteState}>
					<Tabs
						id="body-tabs"
						defaultActiveKey="preview"
						className="mb-3"
						justify
					>
						<Tab eventKey="preview" title="Preview">
							<section className="App--preview">
								<Preview />
							</section>
						</Tab>
						<Tab eventKey="export" title="Export">
							<section className="App--export">
								<Export />
							</section>
						</Tab>
					</Tabs>
				</PaletteStateContext.Provider>
			</div>
		</div>
	);
}

export default App;
