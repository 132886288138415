import {
	PreviewFontColorByWeightBySize,
	previewFontSizes,
} from '../../types/font';
import PreviewTextSizeGroup from '../TextSizeGroup/PreviewTextSizeGroup';
import './PreviewTextGroup.css';

type PreviewTextGroupProps = {
	colorByWeightBySize: PreviewFontColorByWeightBySize;
};

const PreviewTextGroup = ({ colorByWeightBySize }: PreviewTextGroupProps) => {
	const textSizeGroups: JSX.Element[] = [];
	for (const size of previewFontSizes) {
		const colorByWeight = colorByWeightBySize[size];
		if (colorByWeight !== undefined) {
			textSizeGroups.push(
				<PreviewTextSizeGroup
					key={size}
					size={size}
					colorByWeight={colorByWeight}
				/>
			);
		}
	}
	return <div className="PreviewTextGroup">{textSizeGroups}</div>;
};

export default PreviewTextGroup;
