import { APCAcontrast, sRGBtoY } from 'apca-w3';
import chroma from 'chroma-js';
import {
	ApcaColorLevel,
	ColorContrastStandard,
	Wcag2aaColorLevel,
	apcaColorLevels,
	wcagColorLevels,
} from '../../../types/color';
import { Palette } from '../../../types/palette';

const getApcaContrast = (foreground: string, background: string): number =>
	APCAcontrast(
		sRGBtoY(chroma(foreground).rgb()),
		sRGBtoY(chroma(background).rgb())
	) as number;
type RefineApcaColorParams = {
	foreground: string;
	background: string;
	targetContrast: {
		min: number;
		max: number;
	};
};

const refineApcaColor = (params: RefineApcaColorParams): chroma.Color => {
	if (params.targetContrast.min >= params.targetContrast.max) {
		throw new Error(
			'Invalid params for refining contrast: target min must be less than target max'
		);
	}
	if (
		(params.targetContrast.min < 0 && params.targetContrast.max > 0) ||
		(params.targetContrast.min > 0 && params.targetContrast.max < 0)
	) {
		throw new Error(
			'Invalid params for refining contrast: target min and max must be both positive or both negative'
		);
	}

	let contrastAgainstBackground = getApcaContrast(
		params.foreground,
		params.background
	);
	let tooLow = contrastAgainstBackground <= params.targetContrast.min;
	let tooHigh = contrastAgainstBackground >= params.targetContrast.max;
	let luminanceParams = { min: 0, max: 100 };
	// jsonDebug('luminance params:', luminanceParams);
	let chromaColor = chroma(params.foreground);
	// console.debug('color:', chromaColor.hex());
	// console.debug(
	// `contrast against ${params.background}:`,
	// contrastAgainstBackground
	// );
	let iterations = 0;
	let midLuminance = chromaColor.get('lab.l');
	while (tooHigh || tooLow) {
		iterations++;
		// console.debug('iteration:', iterations);
		// jsonDebug('target contrast:', params.targetContrast);
		// jsonDebug('too high/low:', { tooHigh, tooLow });
		if (iterations >= 50) {
			const consolationColorByHue = chromaColor.set('lab.a', '*1.01');
			// console.debug('consolation color:', consolationColorByHue.hex());
			chromaColor = refineApcaColor({
				foreground: consolationColorByHue.hex(),
				background: params.background,
				targetContrast: params.targetContrast,
			});
			break;
		}
		if (tooHigh) {
			luminanceParams.min = chromaColor.get('lab.l');
		} else if (tooLow) {
			luminanceParams.max = chromaColor.get('lab.l');
		} else break;
		// jsonDebug('new luminance params:', luminanceParams);
		midLuminance = (luminanceParams.max + luminanceParams.min) / 2;
		// console.debug('new mid luminance:', midLuminance);
		chromaColor = chromaColor.set('lab.l', midLuminance);
		// console.debug('new color:', chromaColor.hex());
		contrastAgainstBackground = getApcaContrast(
			chromaColor.hex(),
			params.background
		);
		// console.debug(
		// `contrast against ${params.background}:`,
		// contrastAgainstBackground
		// );
		tooLow = contrastAgainstBackground < params.targetContrast.min;
		tooHigh = contrastAgainstBackground > params.targetContrast.max;
	}
	// console.debug('final color:', chromaColor.hex());
	// console.debug('total iterations:', iterations);
	return chromaColor;
};

const apcaColorToPalette = (color: string): Record<ApcaColorLevel, string> => {
	const black = '#000000';
	const white = '#ffffff';
	const lightModeContrast = {
		min: 15,
		max: 15.6,
	};
	const darkModeContrast = {
		min: -15.6,
		max: -15,
	};

	// console.group('L150');
	const l150 = refineApcaColor({
		foreground: color,
		background: white,
		targetContrast: lightModeContrast,
	});
	// console.debug('L150', l150.hex());
	// console.groupEnd();

	// console.group('L300');
	const l300 = refineApcaColor({
		foreground: color,
		background: l150.hex(),
		targetContrast: lightModeContrast,
	});
	// console.debug('L300', l300.hex());
	// console.groupEnd();

	// console.group('L450');
	const l450 = refineApcaColor({
		foreground: color,
		background: l300.hex(),
		targetContrast: lightModeContrast,
	});
	// console.debug('L450', l450.hex());
	// console.groupEnd();

	// console.group('L600');
	const l600 = refineApcaColor({
		foreground: color,
		background: l450.hex(),
		targetContrast: lightModeContrast,
	});
	// console.debug('L600', l600.hex());
	// console.groupEnd();

	// console.group('L750');
	const l750 = refineApcaColor({
		foreground: color,
		background: l600.hex(),
		targetContrast: lightModeContrast,
	});
	// console.debug('L750', l750.hex());
	// console.groupEnd();

	// console.group('L900');
	const l900 = refineApcaColor({
		foreground: color,
		background: l750.hex(),
		targetContrast: lightModeContrast,
	});
	// console.debug(
	// 'black contrast against L900:',
	// getApcaContrast(black, l900.hex())
	// );
	// console.debug('L900', l900.hex());

	// console.groupEnd();

	// console.group('D900');
	const d150 = refineApcaColor({
		foreground: color,
		background: black,
		targetContrast: darkModeContrast,
	});
	// console.debug('D900', d900.hex());
	// console.groupEnd();

	// console.group('D750');
	const d300 = refineApcaColor({
		foreground: color,
		background: d150.hex(),
		targetContrast: darkModeContrast,
	});
	// console.debug('D750', d750.hex());
	// console.groupEnd();

	// console.group('D600');
	const d450 = refineApcaColor({
		foreground: color,
		background: d300.hex(),
		targetContrast: darkModeContrast,
	});
	// console.debug('D600', d600.hex());
	// console.groupEnd();

	// console.group('D450');
	const d600 = refineApcaColor({
		foreground: color,
		background: d450.hex(),
		targetContrast: darkModeContrast,
	});
	// console.debug('D450', d450.hex());
	// console.groupEnd();

	// console.group('D300');
	const d750 = refineApcaColor({
		foreground: color,
		background: d600.hex(),
		targetContrast: darkModeContrast,
	});
	// console.debug('D300', d300.hex());
	// console.groupEnd();

	// console.group('D150');
	const d900 = refineApcaColor({
		foreground: color,
		background: d750.hex(),
		targetContrast: darkModeContrast,
	});
	// console.debug('D150', d150.hex());
	// console.groupEnd();
	// console.debug('APCA white/L150 (Lc):', getApcaContrast(l150.hex(), white));
	// console.debug('APCA white/L300 (Lc):', getApcaContrast(l300.hex(), white));
	// console.debug('APCA white/L450 (Lc):', getApcaContrast(l450.hex(), white));
	// console.debug('APCA white/L600 (Lc):', getApcaContrast(l600.hex(), white));
	// console.debug('APCA white/L750 (Lc):', getApcaContrast(l750.hex(), white));
	// console.debug('APCA white/L900 (Lc):', getApcaContrast(l900.hex(), white));
	// console.debug(
	// 	'APCA L150/L300 (Lc):',
	// 	getApcaContrast(l300.hex(), l150.hex())
	// );
	// console.debug(
	// 	'APCA L150/L450 (Lc):',
	// 	getApcaContrast(l450.hex(), l150.hex())
	// );
	// console.debug(
	// 	'APCA L150/L600 (Lc):',
	// 	getApcaContrast(l600.hex(), l150.hex())
	// );
	// console.debug(
	// 	'APCA L150/L750 (Lc):',
	// 	getApcaContrast(l750.hex(), l150.hex())
	// );
	// console.debug(
	// 	'APCA L150/L900 (Lc):',
	// 	getApcaContrast(l900.hex(), l150.hex())
	// );
	// console.debug('APCA L150/black (Lc):', getApcaContrast(black, l150.hex()));
	// console.debug('APCA black/D900 (Lc):', getApcaContrast(d900.hex(), black));
	// console.debug('APCA black/D750 (Lc):', getApcaContrast(d750.hex(), black));
	// console.debug('APCA black/D600 (Lc):', getApcaContrast(d600.hex(), black));
	// console.debug('APCA black/D450 (Lc):', getApcaContrast(d450.hex(), black));
	// console.debug('APCA black/D300 (Lc):', getApcaContrast(d300.hex(), black));
	// console.debug('APCA black/D150 (Lc):', getApcaContrast(d150.hex(), black));
	// console.debug(
	// 	'APCA D900/D750 (Lc):',
	// 	getApcaContrast(d750.hex(), d900.hex())
	// );
	// console.debug(
	// 	'APCA D900/D600 (Lc):',
	// 	getApcaContrast(d600.hex(), d900.hex())
	// );
	// console.debug(
	// 	'APCA D900/D450 (Lc):',
	// 	getApcaContrast(d450.hex(), d900.hex())
	// );
	// console.debug(
	// 	'APCA D900/D300 (Lc):',
	// 	getApcaContrast(d300.hex(), d900.hex())
	// );
	// console.debug(
	// 	'APCA D900/D150 (Lc):',
	// 	getApcaContrast(d150.hex(), d900.hex())
	// );
	// console.debug('APCA D900/white (Lc):', getApcaContrast(white, d900.hex()));
	// console.debug(
	// 	'APCA D750/D600 (Lc):',
	// 	getApcaContrast(d600.hex(), d750.hex())
	// );
	// console.debug(
	// 	'APCA D750/D450 (Lc):',
	// 	getApcaContrast(d450.hex(), d750.hex())
	// );
	// console.debug(
	// 	'APCA D750/D300 (Lc):',
	// 	getApcaContrast(d300.hex(), d750.hex())
	// );
	// console.debug(
	// 	'APCA D750/D150 (Lc):',
	// 	getApcaContrast(d150.hex(), d750.hex())
	// );
	// console.debug('APCA D750:', d750.hex());
	// console.debug('APCA D750/white (Lc):', getApcaContrast(white, d750.hex()));
	const palette = {
		APCA_BLACK: black,
		APCA_WHITE: white,
		APCA_L150: l150.hex(),
		APCA_L300: l300.hex(),
		APCA_L450: l450.hex(),
		APCA_L600: l600.hex(),
		APCA_L750: l750.hex(),
		APCA_L900: l900.hex(),
		APCA_D150: d150.hex(),
		APCA_D300: d300.hex(),
		APCA_D450: d450.hex(),
		APCA_D600: d600.hex(),
		APCA_D750: d750.hex(),
		APCA_D900: d900.hex(),
	};
	return palette;
};

type RefineColorParams = {
	color: string;
	luminance: {
		min: number;
		max: number;
	};
	targetContrast: {
		min: number;
		max: number;
	};
};
const refineWcagColor = (params: RefineColorParams): chroma.Color => {
	const chromaBlack: chroma.Color = chroma('#000000');
	let contrastAgainstBlack = chroma.contrast(chromaBlack, params.color);
	let tooLow = contrastAgainstBlack <= params.targetContrast.min;
	let tooHigh = contrastAgainstBlack >= params.targetContrast.max;
	let luminanceParams = { ...params.luminance };
	let chromaColor = chroma(params.color);
	while (tooHigh || tooLow) {
		if (tooHigh) {
			luminanceParams.max = chromaColor.get('lab.l');
		} else if (tooLow) {
			luminanceParams.min = chromaColor.get('lab.l');
		}
		const midLuminance: number =
			(luminanceParams.max + luminanceParams.min) / 2;
		chromaColor = chromaColor.set('lab.l', midLuminance);
		contrastAgainstBlack = chroma.contrast(chromaBlack, chromaColor);
		tooLow = contrastAgainstBlack <= params.targetContrast.min;
		tooHigh = contrastAgainstBlack >= params.targetContrast.max;
	}
	return chromaColor;
};

const wcag21aaaColorToPalette = (
	color: string
): Record<Wcag2aaColorLevel, string> => {
	const l100 = refineWcagColor({
		color,
		luminance: { min: 0, max: 100 },
		targetContrast: { min: 14, max: 20.9 },
	});
	const l300 = refineWcagColor({
		color,
		luminance: { min: 0, max: 100 },
		targetContrast: { min: 6.75, max: 7 },
	});
	const l500 = refineWcagColor({
		color,
		luminance: { min: 0, max: 100 },
		targetContrast: { min: 4.55, max: 4.6 },
	});
	const l700 = refineWcagColor({
		color,
		luminance: { min: 0, max: 100 },
		targetContrast: { min: 3, max: 3.111111111111111 },
	});
	const l900 = refineWcagColor({
		color,
		luminance: { min: 0, max: 100 },
		targetContrast: { min: 1.1, max: 1.5 },
	});
	// console.debug('WCAG2AA white/L900:', chroma.contrast(l900, '#ffffff'));
	// console.debug(
	// 	'WCAG2AA white/L900 (Lc):',
	// 	getApcaContrast(l900.hex(), '#ffffff')
	// );
	// console.debug('WCAG2AA white/L700:', chroma.contrast(l700, '#ffffff'));
	// console.debug(
	// 	'WCAG2AA white/L700 (Lc):',
	// 	getApcaContrast(l700.hex(), '#ffffff')
	// );
	// console.debug('WCAG2AA white/L500:', chroma.contrast(l500, '#ffffff'));
	// console.debug(
	// 	'WCAG2AA white/L500 (Lc):',
	// 	getApcaContrast(l500.hex(), '#ffffff')
	// );
	// console.debug('WCAG2AA white/L300:', chroma.contrast(l300, '#ffffff'));
	// console.debug(
	// 	'WCAG2AA white/L300 (Lc):',
	// 	getApcaContrast(l300.hex(), '#ffffff')
	// );
	// console.debug('WCAG2AA L100/black:', chroma.contrast('#000000', l100));
	// console.debug(
	// 	'WCAG2AA L100/black (Lc):',
	// 	getApcaContrast('#000000', l100.hex())
	// );
	// console.debug('WCAG2AA L100/L900:', chroma.contrast(l900, l100));
	// console.debug(
	// 	'WCAG2AA L100/L900 (Lc):',
	// 	getApcaContrast(l900.hex(), l100.hex())
	// );
	// console.debug('WCAG2AA L100/L700:', chroma.contrast(l700, l100));
	// console.debug(
	// 	'WCAG2AA L100/L700 (Lc):',
	// 	getApcaContrast(l700.hex(), l100.hex())
	// );
	// console.debug('WCAG2AA L100/L500:', chroma.contrast(l500, l100));
	// console.debug(
	// 	'WCAG2AA L100/L500 (Lc):',
	// 	getApcaContrast(l500.hex(), l100.hex())
	// );
	// console.debug('WCAG2AA black/L900:', chroma.contrast(l900, '#000000'));
	// console.debug(
	// 	'WCAG2AA black/L900 (Lc):',
	// 	getApcaContrast(l900.hex(), '#000000')
	// );
	// console.debug('WCAG2AA black/L700:', chroma.contrast(l700, '#000000'));
	// console.debug(
	// 	'WCAG2AA black/L700 (Lc):',
	// 	getApcaContrast(l700.hex(), '#000000')
	// );
	// console.debug('WCAG2AA black/L500:', chroma.contrast(l500, '#000000'));
	// console.debug(
	// 	'WCAG2AA black/L500 (Lc):',
	// 	getApcaContrast(l500.hex(), '#000000')
	// );
	// console.debug('WCAG2AA black/L300:', chroma.contrast(l300, '#000000'));
	// console.debug(
	// 	'WCAG2AA black/L300 (Lc):',
	// 	getApcaContrast(l300.hex(), '#000000')
	// );
	// console.debug('WCAG2AA black/L100:', chroma.contrast(l100, '#000000'));
	// console.debug(
	// 	'WCAG2AA black/L100 (Lc):',
	// 	getApcaContrast(l100.hex(), '#000000')
	// );
	// console.debug('WCAG2AA L900/L700:', chroma.contrast(l700, l900));
	// console.debug(
	// 	'WCAG2AA L900/L700 (Lc):',
	// 	getApcaContrast(l700.hex(), l900.hex())
	// );
	// console.debug('WCAG2AA L900/L500:', chroma.contrast(l500, l900));
	// console.debug(
	// 	'WCAG2AA L900/L500 (Lc):',
	// 	getApcaContrast(l500.hex(), l900.hex())
	// );
	// console.debug('WCAG2AA L900/L300:', chroma.contrast(l300, l900));
	// console.debug(
	// 	'WCAG2AA L900/L300 (Lc):',
	// 	getApcaContrast(l300.hex(), l900.hex())
	// );
	// console.debug('WCAG2AA L900/L100:', chroma.contrast(l100, l900));
	// console.debug(
	// 	'WCAG2AA L900/L100 (Lc):',
	// 	getApcaContrast(l100.hex(), l900.hex())
	// );
	// console.debug('WCAG2AA L900/white:', chroma.contrast('#ffffff', l900));
	// console.debug(
	// 	'WCAG2AA L900/white (Lc):',
	// 	getApcaContrast('#ffffff', l900.hex())
	// );
	const result: Record<Wcag2aaColorLevel, string> = {
		WCAG_2_1_AAA_WHITE: '#ffffff',
		WCAG_2_1_AAA_BLACK: '#000000',
		WCAG_2_1_AAA_L100: l100.hex(),
		WCAG_2_1_AAA_L300: l300.hex(),
		WCAG_2_1_AAA_L500: l500.hex(),
		WCAG_2_1_AAA_L700: l700.hex(),
		WCAG_2_1_AAA_L900: l900.hex(),
	};
	return result;
};

type ColorToPaletteParams = {
	color: string;
};

type ColorToPalettesParams = {
	colors: string[];
};

const colorToPalette = ({ color }: ColorToPaletteParams): Palette => {
	const result = {
		base: color,
		colors: {
			...apcaColorToPalette(color),
			...wcag21aaaColorToPalette(color),
		},
	};
	// jsonDebug('colorToPaletteResult:', result);
	return result;
};

const colorsToPalettes = ({ colors }: ColorToPalettesParams): Palette[] =>
	colors.map((color) => colorToPalette({ color }));

const standardToColorLevels = (standard: ColorContrastStandard) => {
	switch (standard) {
		case 'apca':
			return apcaColorLevels;
		case 'wcag21aaa':
			return wcagColorLevels;
		default:
			const invalidStandard: never = standard;
			throw new Error('invalid standard', invalidStandard);
	}
};

const PaletteHelper = {
	colorToPalette,
	colorsToPalettes,
	standardToColorLevels,
};

export default PaletteHelper;
