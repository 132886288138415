import { createContext } from 'react';
import {
	AnyColorLevel,
	ColorContrastStandard,
	apcaColorLevels,
} from '../../../types/color';
import { Palette } from '../../../types/palette';
import PaletteHelper from '../helpers/PaletteHelper';

export const DEFAULT_PALETTE: Palette = Object.freeze(
	PaletteHelper.colorToPalette({
		color: '#000000',
	})
);

export type PaletteState = {
	standard: ColorContrastStandard;
	palette: Palette;
	colorLevels: AnyColorLevel[];
};

export const PaletteStateContext = createContext<PaletteState>({
	standard: 'apca',
	palette: DEFAULT_PALETTE,
	colorLevels: apcaColorLevels,
});

export const PaletteContext = createContext<Palette>(DEFAULT_PALETTE);
