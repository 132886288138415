import { useContext } from 'react';
import { ColorContrastStandard } from '../../../../types/color';
import { PaletteStateContext } from '../../../palette/contexts/PaletteStateContext';
import { ColorModeContext } from '../../contexts/ColorModeContext';
import PreviewButton, { PreviewButtonProps } from '../Button/PreviewButton';
import './PreviewButtonGroup.css';

export const buttonColorsByColorModeByStandard: Record<
	ColorContrastStandard,
	Record<'light' | 'dark', PreviewButtonProps['color'][]>
> = {
	apca: {
		light: [
			{
				background: 'APCA_D300',
				text: 'APCA_WHITE',
			},
			{
				background: 'APCA_L150',
				text: 'APCA_L900',
				border: 'APCA_L600',
			},
			{
				background: 'APCA_BLACK',
				text: 'APCA_D900',
			},
			{
				background: 'APCA_WHITE',
				text: 'APCA_L900',
				border: 'APCA_L600',
			},
		],
		dark: [
			{
				background: 'APCA_L150',
				text: 'APCA_L900',
			},
			{
				background: 'APCA_D150',
				text: 'APCA_D900',
				border: 'APCA_D600',
			},
			{
				background: 'APCA_WHITE',
				text: 'APCA_L900',
			},
			{
				background: 'APCA_BLACK',
				text: 'APCA_D900',
				border: 'APCA_D600',
			},
		],
	},
	wcag21aaa: {
		light: [
			{
				background: 'WCAG_2_1_AAA_L900',
				text: 'WCAG_2_1_AAA_WHITE',
			},
			{
				background: 'WCAG_2_1_AAA_L100',
				text: 'WCAG_2_1_AAA_L900',
				border: 'WCAG_2_1_AAA_L500',
			},
			{
				background: 'WCAG_2_1_AAA_BLACK',
				text: 'WCAG_2_1_AAA_L100',
			},
			{
				background: 'WCAG_2_1_AAA_WHITE',
				text: 'WCAG_2_1_AAA_L900',
				border: 'WCAG_2_1_AAA_L500',
			},
		],
		dark: [
			{
				background: 'WCAG_2_1_AAA_L100',
				text: 'WCAG_2_1_AAA_BLACK',
			},
			{
				background: 'WCAG_2_1_AAA_L900',
				text: 'WCAG_2_1_AAA_WHITE',
				border: 'WCAG_2_1_AAA_L500',
			},
			{
				background: 'WCAG_2_1_AAA_WHITE',
				text: 'WCAG_2_1_AAA_L900',
			},
			{
				background: 'WCAG_2_1_AAA_BLACK',
				text: 'WCAG_2_1_AAA_L100',
				border: 'WCAG_2_1_AAA_L500',
			},
		],
	},
};

const PreviewButtonGroup = () => {
	const colorMode = useContext(ColorModeContext);
	const { standard } = useContext(PaletteStateContext);
	const buttons: JSX.Element[] = [];
	buttonColorsByColorModeByStandard[standard][colorMode].forEach(
		(color, index) => {
			buttons.push(<PreviewButton key={index} color={color} />);
		}
	);
	return <div className="PreviewButtonGroup">{buttons}</div>;
};
export default PreviewButtonGroup;
