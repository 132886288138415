import { Dispatch, createContext } from 'react';
import { apcaColorLevels } from '../../../types/color';
import { Palette } from '../../../types/palette';
import { PalettesAction, PalettesState } from '../reducers/usePalettesReducer';
import { DEFAULT_PALETTE } from './PaletteStateContext';

export const DEFAULT_PALETTES: Palette[] = [DEFAULT_PALETTE];

export const PalettesDispatchContext =
	createContext<Dispatch<PalettesAction> | null>(null);

const defaultPalettesState: PalettesState = {
	standard: 'apca',
	palettes: [DEFAULT_PALETTE],
	colorLevels: apcaColorLevels,
};

export const PalettesStateContext =
	createContext<PalettesState>(defaultPalettesState);
