import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { AnyColorLevel, colorLevelDisplayMap } from '../../../../types/color';
import { PaletteStateContext } from '../../../palette/contexts/PaletteStateContext';
import { ColorModeContext } from '../../contexts/ColorModeContext';
import './PreviewButton.css';

export type PreviewButtonProps = {
	color: {
		background: AnyColorLevel;
		text: AnyColorLevel;
		border?: AnyColorLevel;
	};
};

const PreviewButton = ({
	color: { background, text, border },
}: PreviewButtonProps) => {
	const { palette } = useContext(PaletteStateContext);
	const colorMode = useContext(ColorModeContext);
	const color = palette.colors[text];
	const borderColor = border ? palette.colors[border] : 'transparent';
	const backgroundColor = palette.colors[background];
	const labelColor = colorMode === 'light' ? 'black' : 'white';
	return (
		<div className="PreviewButton">
			<div className="PreviewButton--label-group" style={{ color: labelColor }}>
				<p className="PreviewButton--label">
					{border
						? colorLevelDisplayMap[border].label
						: colorLevelDisplayMap[background].label}{' '}
					border
				</p>
				<p className="PreviewButton--label">
					{colorLevelDisplayMap[background].label} background
				</p>
			</div>
			<Button
				className={`
					PreviewButton--button
				`}
				style={{
					backgroundColor,
					color,
					borderColor,
				}}
			>
				({colorLevelDisplayMap[text].label}) 14px Lor
			</Button>
		</div>
	);
};

export default PreviewButton;
