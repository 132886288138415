import { useContext } from 'react';
import {
	AnyBackgroundColorLevel,
	ColorContrastStandard,
	ColorMode,
} from '../../../../types/color';
import { PaletteStateContext } from '../../../palette/contexts/PaletteStateContext';
import { ColorModeContext } from '../../contexts/ColorModeContext';
import PreviewContent from '../Content/PreviewContent';

type PreviewSectionBackgroundColorLevels = {
	0: AnyBackgroundColorLevel;
	1?: AnyBackgroundColorLevel;
	2?: AnyBackgroundColorLevel;
};

type ColorModeToPreviewSectionBackgroundColorLevels = {
	[Mode in ColorMode]: PreviewSectionBackgroundColorLevels;
};

type StandardColorModeToPreviewSectionBackgroundColorLevels = {
	[Standard in ColorContrastStandard]: ColorModeToPreviewSectionBackgroundColorLevels;
};

const colorModeToPreviewBackgroundColorLevels: Readonly<StandardColorModeToPreviewSectionBackgroundColorLevels> =
	{
		apca: {
			light: ['APCA_WHITE', 'APCA_L150'],
			dark: ['APCA_BLACK', 'APCA_D150'],
		},
		wcag21aaa: {
			light: ['WCAG_2_1_AAA_WHITE', 'WCAG_2_1_AAA_L100'],
			dark: ['WCAG_2_1_AAA_BLACK', 'WCAG_2_1_AAA_L900'],
		},
	};

export type PreviewSectionProps = {
	colorMode: ColorMode;
};

const PreviewSection = ({ colorMode }: PreviewSectionProps) => {
	const { standard } = useContext(PaletteStateContext);
	const previewBackgroundColorLevels =
		colorModeToPreviewBackgroundColorLevels[standard][colorMode];
	return (
		<div className="PreviewSection">
			<ColorModeContext.Provider value={colorMode}>
				<PreviewContent backgroundColorLevel={previewBackgroundColorLevels[0]}>
					{!!previewBackgroundColorLevels[1] && (
						<PreviewContent
							backgroundColorLevel={previewBackgroundColorLevels[1]}
						>
							{!!previewBackgroundColorLevels[2] && (
								<PreviewContent
									backgroundColorLevel={previewBackgroundColorLevels[2]}
								/>
							)}
						</PreviewContent>
					)}
				</PreviewContent>
			</ColorModeContext.Provider>
		</div>
	);
};

export default PreviewSection;
