import { useContext } from 'react';
import { PaletteStateContext } from '../../../palette/contexts/PaletteStateContext';
import CopyToClipboardButton from '../CopyToClipboardButton/CopyToClipboardButton';
import './Export.css';

const Export = () => {
	const { palette, standard } = useContext(PaletteStateContext);
	let css = `:root {
	--color-primary-base: ${palette.base};
	--color-primary-light-150: ${palette.colors.APCA_L150};
	--color-primary-light-300: ${palette.colors.APCA_L300};
	--color-primary-light-450: ${palette.colors.APCA_L450};
	--color-primary-light-600: ${palette.colors.APCA_L600};
	--color-primary-light-750: ${palette.colors.APCA_L750};
	--color-primary-light-900: ${palette.colors.APCA_L900};
	--color-primary-dark-150: ${palette.colors.APCA_D150};
	--color-primary-dark-300: ${palette.colors.APCA_D300};
	--color-primary-dark-450: ${palette.colors.APCA_D450};
	--color-primary-dark-600: ${palette.colors.APCA_D600};
	--color-primary-dark-750: ${palette.colors.APCA_D750};
	--color-primary-dark-900: ${palette.colors.APCA_D900};
}`;
	if (standard === 'wcag21aaa') {
		css = `:root {
	--color-primary-base: ${palette.base};
	--color-primary-light-100: ${palette.colors.WCAG_2_1_AAA_L100};
	--color-primary-light-300: ${palette.colors.WCAG_2_1_AAA_L300};
	--color-primary-light-500: ${palette.colors.WCAG_2_1_AAA_L500};
	--color-primary-light-700: ${palette.colors.WCAG_2_1_AAA_L700};
	--color-primary-light-900: ${palette.colors.WCAG_2_1_AAA_L900};
}`;
	}
	return (
		<div className="Export">
			<h2>Export</h2>
			<h3>CSS</h3>
			<CopyToClipboardButton label="CSS" copyText={css} />
			<pre className="Export--css">{css}</pre>
		</div>
	);
};
export default Export;
