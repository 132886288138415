import { useReducer } from 'react';
import { AnyColorLevel, ColorContrastStandard } from '../../../types/color';
import { Palette } from '../../../types/palette';
import PaletteHelper from '../helpers/PaletteHelper';

export type PalettesState = {
	standard: ColorContrastStandard;
	palettes: Palette[];
	colorLevels: AnyColorLevel[];
};
export type PalettesAction =
	| {
			type: 'setBaseColor';
			payload: {
				index: number;
				color: string;
			};
	  }
	| {
			type: 'setStandard';
			payload: {
				standard: ColorContrastStandard;
			};
	  };

const palettesReducer = (
	state: PalettesState,
	action: PalettesAction
): PalettesState => {
	switch (action.type) {
		case 'setBaseColor': {
			const baseColors: string[] = state.palettes.map(
				(palette) => palette.base
			);
			baseColors[action.payload.index] = action.payload.color;
			const newPalettes = PaletteHelper.colorsToPalettes({
				colors: baseColors,
			});
			return { ...state, palettes: newPalettes };
		}
		case 'setStandard': {
			const newColorLevels = PaletteHelper.standardToColorLevels(
				action.payload.standard
			);
			const newState = {
				...state,
				standard: action.payload.standard,
				colorLevels: newColorLevels,
			};
			return newState;
		}
		default:
			const invalidAction: never = action;
			throw new Error('Invalid action', invalidAction);
	}
};

export const usePalettesReducer = (
	initialStandard: ColorContrastStandard,
	initialBaseColors: string[]
) => {
	const initialPalettes = PaletteHelper.colorsToPalettes({
		colors: initialBaseColors,
	});
	const initialColorLevels =
		PaletteHelper.standardToColorLevels(initialStandard);
	return useReducer(palettesReducer, {
		standard: initialStandard,
		palettes: initialPalettes,
		colorLevels: initialColorLevels,
	});
};
