import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

type CopyToClipboardButtonProps = {
	copyText: string;
	label: string;
};
const CopyToClipboardButton = ({
	copyText,
	label,
}: CopyToClipboardButtonProps) => {
	const [isCopied, setIsCopied] = useState(false);

	// This is the function we wrote earlier
	const copyTextToClipboard = async (text: string) => {
		if ('clipboard' in navigator) {
			return await navigator.clipboard.writeText(text);
		} else {
			return document.execCommand('copy', true, text);
		}
	};

	// onClick handler function for the copy button
	const handleCopyClick = () => {
		// Asynchronously call copyTextToClipboard
		copyTextToClipboard(copyText)
			.then(() => {
				// If successful, update the isCopied state value
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 1500);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className="CopyToClipboardButton">
			<Button onClick={handleCopyClick}>
				<span>{isCopied ? 'Copied!' : `Copy ${label} to Clipboard`}</span>
			</Button>
		</div>
	);
};

export default CopyToClipboardButton;
