export type ColorMode = 'light' | 'dark';

const COLOR_CONTRAST_STANDARDS = {
	apca: 'apca',
	wcag21aaa: 'wcag21aaa',
};

export type ColorContrastStandard = keyof typeof COLOR_CONTRAST_STANDARDS;

export const isColorContrastStandard = (
	standard: string
): standard is ColorContrastStandard =>
	Object.values(COLOR_CONTRAST_STANDARDS).includes(standard);

export type ApcaBackgroundColorLevel =
	| 'APCA_BLACK'
	| 'APCA_WHITE'
	| 'APCA_L150'
	| 'APCA_D150';

export type Wcag21aaaBackgroundColorLevel =
	| 'WCAG_2_1_AAA_WHITE'
	| 'WCAG_2_1_AAA_L100'
	| 'WCAG_2_1_AAA_BLACK'
	| 'WCAG_2_1_AAA_L900';

export type AnyBackgroundColorLevel =
	| Wcag21aaaBackgroundColorLevel
	| ApcaBackgroundColorLevel;

export type ApcaColorLevel =
	| 'APCA_BLACK'
	| 'APCA_WHITE'
	| 'APCA_L150'
	| 'APCA_L300'
	| 'APCA_L450'
	| 'APCA_L600'
	| 'APCA_L750'
	| 'APCA_L900'
	| 'APCA_D150'
	| 'APCA_D300'
	| 'APCA_D450'
	| 'APCA_D600'
	| 'APCA_D750'
	| 'APCA_D900';

export type Wcag2aaColorLevel =
	| 'WCAG_2_1_AAA_BLACK'
	| 'WCAG_2_1_AAA_WHITE'
	| 'WCAG_2_1_AAA_L100'
	| 'WCAG_2_1_AAA_L300'
	| 'WCAG_2_1_AAA_L500'
	| 'WCAG_2_1_AAA_L700'
	| 'WCAG_2_1_AAA_L900';

type UnionCustomColorLevel = ApcaColorLevel | Wcag2aaColorLevel;

export const apcaColorLevels: ApcaColorLevel[] = [
	'APCA_BLACK',
	'APCA_WHITE',
	'APCA_L150',
	'APCA_L300',
	'APCA_L450',
	'APCA_L600',
	'APCA_L750',
	'APCA_L900',
	'APCA_D150',
	'APCA_D300',
	'APCA_D450',
	'APCA_D600',
	'APCA_D750',
	'APCA_D900',
];

export const wcagColorLevels: Wcag2aaColorLevel[] = [
	'WCAG_2_1_AAA_BLACK',
	'WCAG_2_1_AAA_WHITE',
	'WCAG_2_1_AAA_L100',
	'WCAG_2_1_AAA_L300',
	'WCAG_2_1_AAA_L500',
	'WCAG_2_1_AAA_L700',
	'WCAG_2_1_AAA_L900',
];

type CustomColorLevelAggregate = {
	apca: ApcaColorLevel;
	wcag21aaa: Wcag2aaColorLevel;
};

export type CustomColorLevel<Standard extends ColorContrastStandard> =
	CustomColorLevelAggregate[Standard];

export type ColorLevel<Standard extends ColorContrastStandard> =
	CustomColorLevel<Standard>;

type CustomColorModeLevelAggregate = {
	[Standard in ColorContrastStandard]: CustomColorLevel<Standard>[];
};

export const customLightColorLevels: CustomColorModeLevelAggregate = {
	apca: apcaColorLevels.filter((value) => value.startsWith('APCA_L')),
	wcag21aaa: wcagColorLevels.filter((value) =>
		value.startsWith('WCAG_2_1_AAA_L')
	),
} as const;
export const customDarkColorLevels: CustomColorModeLevelAggregate = {
	apca: apcaColorLevels.filter((value) => value.startsWith('APCA_D')),
	wcag21aaa: wcagColorLevels.filter((value) =>
		value.startsWith('WCAG_2_1_AAA_D')
	),
} as const;

export type AnyColorLevel = UnionCustomColorLevel;

type ColorLevelDisplay = {
	label: string;
	cssSelector: string;
	paletteTextColor: 'white' | 'black';
};

export const colorLevelDisplayMap: Record<AnyColorLevel, ColorLevelDisplay> = {
	APCA_BLACK: {
		label: 'Black',
		cssSelector: 'black',
		paletteTextColor: 'white',
	},
	APCA_WHITE: {
		label: 'White',
		cssSelector: 'white',
		paletteTextColor: 'black',
	},
	APCA_L150: {
		label: 'L150',
		cssSelector: 'light-150',
		paletteTextColor: 'black',
	},
	APCA_L300: {
		label: 'L300',
		cssSelector: 'light-300',
		paletteTextColor: 'black',
	},
	APCA_L450: {
		label: 'L450',
		cssSelector: 'light-450',
		paletteTextColor: 'black',
	},
	APCA_L600: {
		label: 'L600',
		cssSelector: 'light-600',
		paletteTextColor: 'white',
	},
	APCA_L750: {
		label: 'L750',
		cssSelector: 'light-750',
		paletteTextColor: 'white',
	},
	APCA_L900: {
		label: 'L900',
		cssSelector: 'light-900',
		paletteTextColor: 'white',
	},
	APCA_D150: {
		label: 'D150',
		cssSelector: 'dark-150',
		paletteTextColor: 'white',
	},
	APCA_D300: {
		label: 'D300',
		cssSelector: 'dark-300',
		paletteTextColor: 'white',
	},
	APCA_D450: {
		label: 'D450',
		cssSelector: 'dark-450',
		paletteTextColor: 'white',
	},
	APCA_D600: {
		label: 'D600',
		cssSelector: 'dark-600',
		paletteTextColor: 'black',
	},
	APCA_D750: {
		label: 'D750',
		cssSelector: 'dark-750',
		paletteTextColor: 'black',
	},
	APCA_D900: {
		label: 'D900',
		cssSelector: 'dark-900',
		paletteTextColor: 'black',
	},
	WCAG_2_1_AAA_BLACK: {
		label: 'Black',
		cssSelector: 'black',
		paletteTextColor: 'white',
	},
	WCAG_2_1_AAA_WHITE: {
		label: 'White',
		cssSelector: 'white',
		paletteTextColor: 'black',
	},
	WCAG_2_1_AAA_L100: {
		label: 'L100',
		cssSelector: 'light-100',
		paletteTextColor: 'black',
	},
	WCAG_2_1_AAA_L300: {
		label: 'L300',
		cssSelector: 'light-300',
		paletteTextColor: 'black',
	},
	WCAG_2_1_AAA_L500: {
		label: 'L500',
		cssSelector: 'light-500',
		paletteTextColor: 'white',
	},
	WCAG_2_1_AAA_L700: {
		label: 'L700',
		cssSelector: 'light-700',
		paletteTextColor: 'white',
	},
	WCAG_2_1_AAA_L900: {
		label: 'L900',
		cssSelector: 'light-900',
		paletteTextColor: 'white',
	},
} as const;
