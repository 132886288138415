import { AnyColorLevel } from '../../../types/color';

export type GlobalPreviewFontSize = 14 | 16 | 24;

export type ApcaPreviewFontSize = 18 | 36;
export type WcagPreviewFontSize = 20;
export type PreviewFontSize =
	| GlobalPreviewFontSize
	| ApcaPreviewFontSize
	| WcagPreviewFontSize;

export const previewFontSizes: readonly PreviewFontSize[] = [
	36, 24, 20, 18, 16, 14,
] as const;

export const PreviewFontSizes: Record<PreviewFontSize, { text: string }> = {
	14: {
		text: 'morbiptretium volutpat dapibus.',
	},
	16: {
		text: 'morbiptretium volutpat dapibus.',
	},
	18: {
		text: 'morbiptretium volutpat dapibus.',
	},
	20: {
		text: 'morbiptretium volutpat.',
	},
	24: {
		text: 'morbiptretium volutpat.',
	},
	36: {
		text: 'Morbiptretium',
	},
};

export type PreviewFontWeight = 'bold' | 'normal' | 'light';

export const PreviewFontWeights: Record<PreviewFontWeight, { text: string }> = {
	bold: {
		text: 'Bold',
	},
	normal: {
		text: 'Normal',
	},
	light: {
		text: 'Light',
	},
};

export type PreviewFontColorByWeight = {
	bold: AnyColorLevel;
	normal?: AnyColorLevel;
	light?: AnyColorLevel;
};

export type PreviewFontColorByWeightBySize = {
	[size in GlobalPreviewFontSize]: PreviewFontColorByWeight;
} & {
	[size in ApcaPreviewFontSize]?: PreviewFontColorByWeight;
} & {
	20?: PreviewFontColorByWeight;
};
