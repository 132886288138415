import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { ColorMode } from '../../../../types/color';
import PreviewSection from '../Section/PreviewSection';
import './Preview.css';

const Preview = () => {
	const [mobileColorMode, setMobileColorMode] = useState<ColorMode>('light');
	return (
		<div className="Preview">
			<h2>Preview</h2>
			<div className="Preview--modes Preview-modes Preview-modes__layout--mobile">
				<Form className="Preview-modes--mode-selector">
					<Form.Group controlId="previewColorMode">
						<Form.Check
							inline
							type="radio"
							id="Preview-modes__mode-selector--light"
							value="light"
							checked={mobileColorMode === 'light'}
							onChange={() => setMobileColorMode('light')}
							name="color-mode"
							label="Light Mode"
						/>
						<Form.Check
							inline
							type="radio"
							id="Preview-modes__mode-selector--dark"
							value="dark"
							checked={mobileColorMode === 'dark'}
							onChange={() => setMobileColorMode('dark')}
							name="color-mode"
							label="Dark Mode"
						/>
					</Form.Group>
				</Form>
				<PreviewSection colorMode={mobileColorMode} />
			</div>
			<div className="Preview--modes Preview-modes Preview-modes__layout--desktop">
				<div className="Preview--mode">
					<PreviewSection colorMode="light" />
				</div>
				<div className="Preview--mode">
					<PreviewSection colorMode="dark" />
				</div>
			</div>
		</div>
	);
};

export default Preview;
