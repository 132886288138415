import { useContext } from 'react';
import {
	AnyColorLevel,
	colorLevelDisplayMap,
	customDarkColorLevels,
	customLightColorLevels,
} from '../../../../types/color';
import { Palette as PaletteType } from '../../../../types/palette';
import { PaletteStateContext } from '../../contexts/PaletteStateContext';
import './Palette.css';

const Swatches = (palette: PaletteType, customColorLevels: AnyColorLevel[]) => {
	let swatches = [];
	for (const level of customColorLevels) {
		swatches.push(
			<div
				className="Palette--swatch"
				key={level}
				style={{
					backgroundColor: palette.colors[level],
					color: colorLevelDisplayMap[level].paletteTextColor,
				}}
			>
				<span>{colorLevelDisplayMap[level].label}</span>
				<span>{palette.colors[level]}</span>
			</div>
		);
	}
	return swatches;
};

const Palette = () => {
	const paletteState = useContext(PaletteStateContext);
	// jsonDebug('PaletteComponent PaletteState:', paletteState);
	let lightColors: AnyColorLevel[] = [];
	let darkColors: AnyColorLevel[] = [];
	let subPaletteClassName = 'Palette--sub-palette';
	switch (paletteState.standard) {
		case 'wcag21aaa':
			lightColors = customLightColorLevels.wcag21aaa;
			darkColors = customDarkColorLevels.wcag21aaa;
			subPaletteClassName =
				'Palette--sub-palette Palette--sub-palette__columns--5';
			break;
		case 'apca':
			lightColors = customLightColorLevels.apca;
			darkColors = customDarkColorLevels.apca;
			break;
		default:
			break;
	}
	const lightSwatches = Swatches(paletteState.palette, lightColors);
	const darkSwatches = Swatches(paletteState.palette, darkColors);
	return (
		<div className="Palette">
			<div className={subPaletteClassName}>{lightSwatches}</div>
			<div className={subPaletteClassName}>{darkSwatches}</div>
		</div>
	);
};

export default Palette;
