import { useContext } from 'react';
import { AnyColorLevel, colorLevelDisplayMap } from '../../../../types/color';
import { PaletteStateContext } from '../../../palette/contexts/PaletteStateContext';
import {
	PreviewFontSize,
	PreviewFontSizes,
	PreviewFontWeight,
	PreviewFontWeights,
} from '../../types/font';
import './PreviewText.css';

export type PreviewTextProps = {
	size: PreviewFontSize;
	weight: PreviewFontWeight;
	color: AnyColorLevel;
};

const PreviewText = ({ size, weight, color }: PreviewTextProps) => {
	const { palette } = useContext(PaletteStateContext);
	const textColor = palette.colors[color];
	return (
		<div className="PreviewText">
			<p
				className={`
					PreviewText
					PreviewText__size--${size}
					PreviewText__weight--${weight}
				`}
				style={{
					color: textColor,
				}}
			>
				({colorLevelDisplayMap[color].label}) {size}px{' '}
				{PreviewFontWeights[weight].text} {PreviewFontSizes[size].text}
			</p>
		</div>
	);
};

export default PreviewText;
